import React, { Component } from 'react';
import Fingerprint2 from 'fingerprintjs2';

import Common from '../helpers/Common';
import database from '../helpers/database';
import Utils from './../helpers/Utils';

import App from './../components/App';
import Loader from '../components/Loader';

// Create a device id by using a fingerprint if it does not exist
if (!localStorage.getItem('deviceId')) {
  new Fingerprint2().get(function(result) {
    localStorage.setItem('deviceId', result);
  });
}

export default class AppContainer extends Component {
  state = {
    isLoading: true,
    isUserLogged: null
  };

  componentWillMount() {
    // Check if logged else redirect to login page
    // if (!sessionStorage.getItem('token') && window.location.hash !== '#/login') {
    //   window.location.hash = '/logout';
    // }

    // When database ready
    database.on('ready', () => {
      // Check if there is an user token before
      Promise.resolve()
        .then(() => {
          const alive = localStorage.getItem('token-alive')
          
          if (!localStorage.getItem('token')) {
            return Common.getNewToken();
          } else if (alive) {
            const isValid = alive > Date.now()
            if (!isValid) return Common.getNewToken();
          }
        })
        .then(() => {
          database.app.get('user').then(user => {
            // Set the user status to logged
            this.setState({ isUserLogged: Boolean(user) });
          });

          database.app
            .get('language')
            .then(language => {
              if (language) {
                // Get the locales according to the user language
                return database.locale.get(language).then(locale => {
                  if (locale) {
                    // Download the translations for each page reloading
                    this.fetchLocales(language);

                    return locale;
                  } else {
                    return this.fetchLocales(language).then(() => {
                      return database.locale.get(language);
                    });
                  }
                });
              } else {
                // Try to fetch the browser lang
                return this.initLocales().then(() => {
                  return database.app.get('language').then(language => {
                    return this.fetchLocales(language).then(() => {
                      return database.locale.get(language);
                    });
                  });
                });
              }
            })
            .then(locale => {
              // Load the locales
              this.setState({
                isLoading: false,
                locale: locale.language,
                messages: locale.messages
              });
            });
        });

      database.on('changes', () => {
        // Managing of the user session
        console.log('changed');
        database.app.get('user').then(user => {
          if (!this.state.isUserLogged && user) {
            this.setState({ isUserLogged: true });
          } else if (this.state.isUserLogged && !user) {
            this.setState({ isUserLogged: false });
          }

          // Check dispatch action
          database.app.get('dispatchAction').then((action = {}) => {
            const { type, sync = false } = action;

            switch (type) {
              case 'CHANGE_LANG':
                Promise.all([
                  database.locale.get(action.language),
                  database.app.delete('dispatchAction') // Important to avoid infinite loop
                ])
                  .then(([locale]) => {
                    if (!locale || sync) {
                      return this.fetchLocales(action.language).then(() => {
                        return database.locale.get(action.language);
                      });
                    }
                    return locale;
                  })
                  .then(locale => {
                    database.app.put(action.language, 'language').then(() => {
                      this.setState({
                        locale: locale.language,
                        messages: locale.messages
                      });
                    });
                  });
                break;
              default:
                return;
            }
          });
        });
      });
    });
  }

  initLocales() {
    const browserLang = Utils.navigatorLanguage();

    return Common.getCountries('en-GB').then(countries => {
      let country = countries.filter(country => country.default.split('-')[0] === browserLang)[0];

      if (!country) {
        country = countries.filter(country => country.default === 'en-GB')[0];
      }

      return Promise.all([
        database.app.put(country.default, 'language'),
        database.app.put(Number(country.id), 'countryId')
      ]);
    });
  }

  fetchLocales = async language => {
    const messages = await Common.getTranslations(language);
    const countries = await Common.getCountries(language);

    return database.locale.put({
      language,
      messages,
      countries
    });
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <Loader center={true} />;
    }

    return <App {...this.state} />;
  }
}
