import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Chart from 'chart.js';
import moment from 'moment';
import { saveAs } from 'file-saver';
import promiseFinally from 'promise.prototype.finally';

import colors from '../helpers/colors';

import LoaderWrapper from './LoaderWrapper';
import styled from 'styled-components';

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';
export const disemvowel = str => str.replace(/[аеиоуыэюяaeiouy]/gi, '');

class MyFollowUpChart extends Component {
  state = {
    isRequesting: false,
    legendsHTML: null,
    isFirstExerciseTypeButtonActive: true
  };

  componentDidMount() {
    const { currentTab } = this.props;

    this.chart = new Chart(document.querySelector('#controlChart'), {
      type: 'bar',
      data: {
        labels: this.getLabels(),
        datasets: this.getChartData()
      },
      options: {
        barShowStroke: false,
        hover: {
          animationDuration: 0
        },
        animation: {
          onComplete: function() {
            const chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.font = Chart.helpers.fontString(10, 700, 'Montserrat');
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              console.log(dataset);
              const meta = chartInstance.controller.getDatasetMeta(i);
              // Allow to show the value upon the bars
              meta.data.forEach(function(bar, index) {
                const data = dataset.data[index];
                ctx.fillText(`${data}%`, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
        legend: {
          display: false
        },
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 50,
            bottom: 0
          }
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.8,
              categoryPercentage: currentTab === 'week' ? 0.65 : 0.4,
              barThickness: 24,
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: '#333333'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                max: 100,
                display: false,
                beginAtZero: true
              }
            }
          ]
        }
      }
    });

    this.setState({ legendsHTML: this.chart.generateLegend() });
  }

  componentDidUpdate() {
    const { muscleId } = this.props;

    // Add a max value to the chart when the data is in percent
    // It works only when there is not muscle selected
    if (!muscleId && !this.chart.config.options.scales.yAxes[0].ticks.max) {
      this.chart.config.options.scales.yAxes[0].ticks.max = 100;
      this.chart.update();
    } else if (muscleId && this.chart.config.options.scales.yAxes[0].ticks.max) {
      delete this.chart.config.options.scales.yAxes[0].ticks.max;
      this.chart.update();
    }
  }

  getLabels = () => {
    const { intl, currentTab, currentDate } = this.props;
    const _ = id => intl.formatHTMLMessage({ id });
    const month = currentDate.split('-')[1];
    const mnt = moment(currentDate);
    const daysInMonth = mnt.daysInMonth();

    let labels;

    mnt.subtract(1, 'days');

    if (currentTab === 'week') {
      labels = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ].map(name => [
        disemvowel(capitalize(_('week_' + name))).slice(0, 2),
        mnt.add(1, 'days').format('DD')
      ]);
    } else {
      labels = [
        ['01/' + month, '07/' + month],
        ['08/' + month, '15/' + month],
        ['16/' + month, '23/' + month],
        ['24/' + month, daysInMonth + '/' + month]
      ];
    }

    return labels;
  };

  getChartData = () => {
    const { intl, currentTab, muscleId, currentDate, exercisesByPeriod } = this.props;
    const { isFirstExerciseTypeButtonActive } = this.state;
    const _ = id => intl.formatHTMLMessage({ id });
    const preprocess = {};
    const hasTimesSet = {};
    const datasets = [
      {
        label: _('stretchings'),
        backgroundColor: '#E86869',
        data: new Array(currentTab === 'week' ? 7 : 4).fill(0),
        borderWidth: 0
      },
      {
        label: _('trainings'),
        backgroundColor: '#CD8BB7',
        data: new Array(currentTab === 'week' ? 7 : 4).fill(0),
        borderWidth: 0
      }
    ];

    exercisesByPeriod.forEach(period => {
      let startMonth;
      let endMonth;

      if (currentTab === 'week') {
        startMonth = moment(currentDate);
        // end of the week to sunday
        endMonth = moment(currentDate)
          .add(6, 'day')
          .endOf('day');
      } else {
        startMonth = moment(currentDate)
          .clone()
          .startOf('month')
          .format();
        endMonth = moment(currentDate)
          .clone()
          .endOf('month')
          .format();
      }

      if (
        moment(period.start).isSameOrBefore(endMonth) &&
        moment(period.end).isSameOrAfter(startMonth)
      ) {
        for (let id in period.exercises) {
          if (period.exercises.hasOwnProperty(id)) {
            if (!muscleId || (muscleId && period.exercises[id].muscleId === Number(muscleId))) {
              const exercise = period.exercises[id];
              const items = exercise.items;
              const mnt = moment(currentDate);
              const rangeOfDays = currentTab === 'week' ? 7 : mnt.daysInMonth();

              if (currentTab === 'month') {
                mnt.startOf('month');
              } else {
                mnt.subtract(1, 'days');
              }

              for (let i = 0; i < rangeOfDays; i++) {
                const date =
                  currentTab === 'week'
                    ? mnt.add(1, 'days').format('YYYY-MM-DD')
                    : mnt.date(i + 1).format('YYYY-MM-DD');

                if (
                  moment(date).isBetween(
                    moment(period.start).startOf('day'),
                    period.end ? moment(period.end).endOf('day') : moment(),
                    null,
                    '[]'
                  )
                ) {
                  if (!preprocess[date]) {
                    preprocess[date] = [
                      {
                        movements: 0,
                        duration: 0,
                        times: 0,
                        executions: 0
                      },
                      {
                        movements: 0,
                        duration: 0,
                        times: 0,
                        executions: 0
                      }
                    ];
                  }

                  // Allows to kown if the execution number has been already set for an exercise on a given date
                  if (!hasTimesSet[date]) {
                    hasTimesSet[date] = [];
                  }

                  // Set the execution number of the exercise from the most recent prescription
                  if (!hasTimesSet[date][id]) {
                    hasTimesSet[date][id] = true;
                    preprocess[date][exercise.typeId === 1 ? 0 : 1].times = exercise.times;
                  }

                  if (items[date]) {
                    // Total movements
                    preprocess[date][exercise.typeId === 1 ? 0 : 1].movements +=
                      items[date].movements;

                    // Total duration
                    preprocess[date][exercise.typeId === 1 ? 0 : 1].duration +=
                      items[date].duration;

                    // Total executions
                    preprocess[date][exercise.typeId === 1 ? 0 : 1].executions +=
                      items[date].executions;
                  }
                }
              }
            }
          }
        }
      }
    });

    for (let date in preprocess) {
      if (preprocess.hasOwnProperty(date)) {
        const item = preprocess[date];
        const mnt = moment(date);
        const year = currentDate.split('-')[0];
        const month = currentDate.split('-')[1];

        const weekRange = [
          [year + '-' + month + '-01', year + '-' + month + '-07'],
          [year + '-' + month + '-08', year + '-' + month + '-15'],
          [year + '-' + month + '-16', year + '-' + month + '-23'],
          [year + '-' + month + '-24', year + '-' + month + '-' + mnt.daysInMonth()]
        ];

        if (currentTab === 'month') {
          weekRange.forEach((period, i) => {
            if (mnt.isBetween(period[0], period[1], null, '[]')) {
              datasets.forEach((series, j) => {
                if (muscleId) {
                  if (isFirstExerciseTypeButtonActive) {
                    series.data[i] += item[j].duration;
                  } else {
                    series.data[i] += item[j].movements;
                  }
                } else {
                  series.data[i] +=
                    item[j].executions >= item[j].times * 7
                      ? 100
                      : Number((item[j].executions / (item[j].times * 7)) * 100);
                }
              });
            }
          });
        } else {
          if (mnt.isBetween(currentDate, mnt.clone().endOf('isoWeek'), null, '[]')) {
            datasets.forEach((series, i) => {
              if (muscleId) {
                if (isFirstExerciseTypeButtonActive) {
                  series.data[mnt.day() - 1] += item[i].duration;
                } else {
                  series.data[mnt.day() - 1] += item[i].movements;
                }
              } else {
                series.data[mnt.day() - 1] =
                  item[i].executions > item[i].times
                    ? 100
                    : (item[i].executions / item[i].times) * 100;
              }
            });
          }
        }
      }
    }

    if (muscleId) {
      // If it is the stretching filter
      if (isFirstExerciseTypeButtonActive) {
        // Remove the movement data
        datasets.pop();
        datasets.map(series => {
          // Convert value in minute
          series.data = series.data.map(val => Math.ceil(val / 60));
          return series;
        });
      } else {
        // Remove the stretching data
        datasets.shift();
      }
    } else {
      // No filter, therefore display in percent
      datasets.map(series => {
        series.data = series.data.map(val => Math.ceil(val));
        return series;
      });
    }

    return datasets;
  };

  render() {
    const { intl, muscleId, shareMyFollowUp, currentTab, currentDate } = this.props;
    const { isFirstExerciseTypeButtonActive, legendsHTML, isRequesting } = this.state;
    const _ = id => intl.formatHTMLMessage({ id });

    return (
      <div className="MyFollowUpChart">
        {/* <div className="measure">
          {muscleId ? (
            isFirstExerciseTypeButtonActive ? (
              _('unit_minutes_short')
            ) : (
              _('movements_short')
            )
          ) : (
            <span>100%</span>
          )}
        </div> */}
        <ChartHeaders>
          <div className="title">
            <div>
              {muscleId
                ? isFirstExerciseTypeButtonActive
                  ? _('exercise_type_stretching')
                  : _('exercise_type_active')
                : _('followup_progress')}
            </div>
            (
            {muscleId
              ? isFirstExerciseTypeButtonActive
                ? _('minutes_long')
                : _('movements_long')
              : _('percent_long')}
            )
          </div>
          {muscleId ? (
            <div className="actions">
              <button
                onClick={() => {
                  this.setState({ isFirstExerciseTypeButtonActive: true }, () => {
                    this.chart.data.datasets = this.getChartData();
                    this.chart.update();
                  });
                }}
                className={isFirstExerciseTypeButtonActive ? 'active' : null}
              >
                {_('stretchings')}
              </button>
              <button
                onClick={() => {
                  this.setState({ isFirstExerciseTypeButtonActive: false }, () => {
                    this.chart.data.datasets = this.getChartData();
                    this.chart.update();
                  });
                }}
                className={isFirstExerciseTypeButtonActive ? null : 'active'}
              >
                {_('trainings')}
              </button>
            </div>
          ) : (
            <div className="legends" dangerouslySetInnerHTML={{ __html: legendsHTML }} />
          )}
        </ChartHeaders>
        <canvas id="controlChart" width="450" height="310" />
        <DownloadContainer>
          <LoaderWrapper isLoading={isRequesting}>
            <DownloadButton
              onClick={() => {
                const start = currentDate;
                const end = moment(currentDate)
                  .endOf(currentTab === 'week' ? 'isoweek' : 'month')
                  .format('YYYY-MM-DD');

                this.setState({ isRequesting: true });

                promiseFinally(
                  shareMyFollowUp(start, end).then(binary => {
                    saveAs(
                      binary,
                      _('followup_share_filename_excel')
                        .replace('{start}', start)
                        .replace('{end}', end)
                    );
                  }),
                  () => {
                    this.setState({ isRequesting: false });
                  }
                );

                window.gaEvent('share');
              }}
            >
              {_('btn_share')}
            </DownloadButton>
          </LoaderWrapper>
        </DownloadContainer>
      </div>
    );
  }
}

export default injectIntl(MyFollowUpChart);

const ChartHeaders = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DownloadContainer = styled.div`
  .LoaderWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const DownloadButton = styled.button`
  padding: 12px 24px;
  background: linear-gradient(270deg, #d5edf1 0%, #b5cfd3 100%), #fd8d37;
  border-radius: 32px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #333333;
`;
