import React from 'react';
import { HashRouter as Router, Route, Routes, useMatch, useParams } from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import frLocales from 'react-intl/locale-data/fr';
import asyncComponent from '../helpers/asyncComponent';
import ReactQueryProvider from '../helpers/react-query.provider';
import ProtectedRoutes from './ProtectedRoutes';
import AppLayout from './layout/AppLayout';

const LoginContainer = asyncComponent(() => import('../containers/LoginContainer'));
const HomeContainer = asyncComponent(() => import('../containers/HomeContainer'));
const ExerciseContainer = asyncComponent(() => import('../containers/ExerciseContainer'));
const MyProfileContainer = asyncComponent(() => import('../containers/MyProfileContainer'));
const LogoutContainer = asyncComponent(() => import('../containers/LogoutContainer'));
const CreateAccountContainer = asyncComponent(() => import('../containers/CreateAccountContainer'));
const NotificationMessagesContainer = asyncComponent(() =>
  import('../containers/NotificationMessagesContainer')
);
const MyFollowUpContainer = asyncComponent(() => import('../containers/MyFollowUpContainer'));
const PrivacyDataContainer = asyncComponent(() => import('../containers/PrivacyDataContainer'));
const ContactContainer = asyncComponent(() => import('../containers/ContactContainer'));
const EditExercisesContainer = asyncComponent(() => import('../containers/EditExercisesContainer'));
const SettingsContainer = asyncComponent(() => import('../containers/SettingsContainer'));
const GSContractContainer = asyncComponent(() => import('../containers/GSContractContainer'));
const ForgotCodeContainer = asyncComponent(() => import('../containers/ForgotCodeContainer'));
const ResetPasswordContainer = asyncComponent(() => import('../containers/ResetPasswordContainer'));
const DeleteAccountContainer = asyncComponent(() => import('../containers/DeleteAccountContainer'));
const NoMatch = asyncComponent(() => import('../components/NoMatch'));

addLocaleData(frLocales);

export default ({ isUserLogged, messages }) => {
  return (
    <IntlProvider locale="fr-FR" messages={messages}>
      <ReactQueryProvider>
        <NotificationMessagesContainer key="NotificationMessagesContainer" />
        <Router>
          <Routes>
            <Route element={<ProtectedRoutes isUserLogged={isUserLogged} />}>
              <Route exact path="/login" element={<LoginContainer />} />
              <Route exact path="/createAccount" element={<CreateAccountContainer />} />
              <Route exact path="/forgotCode" element={<ForgotCodeContainer />} />

              <Route path="/" element={<AppLayout />}>
                <Route exact path="/home" element={<HomeContainer />} />
                <Route exact path="/myProfile" element={<MyProfileContainer />} />
                <Route exact path="/settings" element={<SettingsContainer />} />
                <Route exact path="/resetPassword" element={<ResetPasswordContainer />} />
                <Route exact path="/myFollowUp" element={<MyFollowUpContainer />} />
                <Route exact path="/privacyData" element={<PrivacyDataContainer />} />
                <Route exact path="contact" element={<ContactContainer />} />
                <Route exact path="/editExercises" element={<EditExercisesContainer />} />
                <Route exact path="/GSContract" element={<GSContractContainer />} />
                <Route exact path="/deleteAccountConfirm" element={<DeleteAccountContainer />} />
                <Route path="/exercise/:id" element={<ExerciseAdapter />}>
                  <Route
                    path="/exercise/:id/:tabId/:fromGSContract"
                    element={<ExerciseAdapter />}
                  />
                  <Route path="/exercise/:id/:tabId" element={<ExerciseAdapter />} />
                  <Route path="/exercise/:id" element={<ExerciseAdapter />} />
                </Route>
              </Route>
            </Route>

            <Route exact path="/logout" element={<LogoutContainer />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </ReactQueryProvider>
    </IntlProvider>
  );
};

const ExerciseAdapter = props => {
  const params = useParams();

  return <ExerciseContainer key={params.id} params={params} />;
};
