import React, { Component } from 'react';

import '../styles/components/Loader.css';

class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: !props.center
    };
  }

  componentDidMount() {
    if (this.props.center) {
      // this.timer = setTimeout(() => {
      //   this.setState({ showLoader: true });
      // }, 1000);
      this.setState({ showLoader: true });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { size = 0.5, center = false } = this.props;
    const { showLoader } = this.state;
    return (
      <div className={`loader ${center ? 'center' : ''}`}>
        {showLoader && (
          <div className="inner">
            <div style={{ width: size * 330, height: size * 330 }} />
            <div
              style={{
                width: size * 249,
                height: size * 249,
                top: size * 15,
                left: size * 40
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Loader;
