import Dexie from 'dexie';
import 'dexie-observable';

const database = new Dexie(`ipsen-${process.env.REACT_APP_VERSION}`);

database.version(process.env.REACT_APP_VERSION).stores({
  app: '&',
  locale: 'language',
  contentTypes: '&id',
  exerciseTypes: '&id',
  limb: '&id',
  articulation: '&id, limb_id',
  muscle: '&id, articulation_id',
  exercise: '&id, muscle_id, exercise_type_id',
  exerciseTab: '&id, exercise_id',
  exerciseContent: '&id, exercise_tab_id, exercise_type_content_id'
});

database.open().catch(function(err) {
  console.error('Failed to open database: ' + (err.stack || err));
});

export default database;
