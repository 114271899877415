import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './containers/AppContainer';
import { unregister } from './registerServiceWorker';

import './styles/index.css';
import TagManager from 'react-gtm-module'

window.appVersion = process.env.REACT_APP_VERSION;

const tagManagerArgs = {
    gtmId: 'GTM-5VMP938'
}
 
TagManager.initialize(tagManagerArgs)

unregister()

ReactDOM.render(<AppContainer />, document.getElementById('root'))
