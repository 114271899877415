import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedDate } from 'react-intl';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import useUser from './../../api/hooks/useUser';
import { useGlobalStore } from '../../stores/globalStore';

const SideMenu = props => {
  const { intl, lastLogin = '', isSideMenuActive } = props;
  const _ = id => intl.formatHTMLMessage({ id });

  const { user } = useUser();
  const setSideMenuActive = useGlobalStore(state => state.setSideMenuActive);

  return (
    <SideMenuContainer className={`${isSideMenuActive ? 'isSideMenuActive' : ''}`}>
      <SideMenuInner className="HomeSideMenuInner">
        <Menu>
          <ul>
            <li>
              <Link
                to="/home"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/my_program', 'ui_menu_click', false);
                }}
              >
                {_('nav_my_program')}
              </Link>
            </li>
            <li>
              <Link
                to="/myProfile"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/my_profil', 'ui_menu_click', false);
                }}
              >
                {_('nav_settings_profile')}
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/my_settings', 'ui_menu_click', false);
                }}
              >
                {_('nav_settings')}
              </Link>
            </li>
            <li>
              <Link
                to="/myFollowUp"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/my_followup', 'ui_menu_click', false);
                }}
              >
                {_('nav_my_followup')}
              </Link>
            </li>
            <li>
              <Link
                to="/GSContract"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/gs_contract', 'ui_menu_click', false);
                }}
              >
                {_('nav_gsr')}
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/contact_us', 'ui_menu_click', false);
                }}
              >
                {_('settings_menu_contact')}
              </Link>
            </li>
            <li>
              <Link
                to="/privacyData"
                tabIndex={isSideMenuActive ? 0 : -1}
                onClick={() => {
                  setSideMenuActive(false);
                  window.gaEvent('menu/warnings', 'ui_menu_click', false);
                }}
              >
                {_('settings_menu_disclaimers')}
              </Link>
            </li>
          </ul>

          <UserInfo>
            <UserName>{user && user.login}</UserName>
            <UserConnection>
              <div>{_('last_connection_title')}</div>
              <FormattedDate
                value={lastLogin.replace(' ', 'T')}
                month="numeric"
                day="numeric"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
                format
              />
            </UserConnection>
          </UserInfo>

          <Link
            className="logout"
            tabIndex={isSideMenuActive ? 0 : -1}
            to="/logout"
            onClick={() => {
              window.gaEvent('menu/logout', 'ui_menu_click', false);
            }}
          >
            <LogoutButton>{_('nav_logout')}</LogoutButton>
          </Link>
        </Menu>
      </SideMenuInner>
    </SideMenuContainer>
  );
};

export default injectIntl(SideMenu);

const UserConnection = styled.div`
  margin-top: 6px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #333333;
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #333333;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0 16px 0;
`;

const LogoutButton = styled.div`
  background: linear-gradient(0deg, #fd8d37, #fd8d37), #ffffff;
  border-radius: 20px;
  padding: 8px 16px;

  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;

  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
`;

const Menu = styled.ul`
  padding: 32px 28px;

  @media (max-width: 575.98px) {
    padding-bottom: 80px;
  }

  li {
    background: linear-gradient(270deg, #d5edf1 0%, #b5cfd3 100%), #ffffff;
    border-radius: 20px;

    a {
      display: block;
      padding: 8px 16px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      color: #333333;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const SideMenuInner = styled.div`
  width: 100%;
  min-height: 100%;
  background: #ffffff;
`;

const SideMenuContainer = styled.nav`
  position: absolute;
  /* right: 1px;
    left: unset;
    top: 1px; */
  left: 10px;
  top: 0px;
  margin: 0 auto;
  width: 226px;
  max-height: 100%;
  /* z-index: 2001; */
  z-index: -1;
  transform: translateX(-226px);
  transition: transform 0.25s, opacity 0.25s;
  opacity: 0;
  pointer-events: none;
  padding-top: 64px;

  @media (max-width: 575.98px) {
    width: 100%;
    height: 100vh;
    max-height: unset;
    overflow-y: auto;
  }

  &.isSideMenuActive {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
`;
