import React from 'react';

const paths = {
  alert_1:
    '<rect x="15.5" y="6.92" width="1" height="15.16"/><circle cx="16" cy="24.16" r="0.92"/><path d="M16,31.55A15.55,15.55,0,1,1,31.55,16,15.57,15.57,0,0,1,16,31.55Zm0-30.1A14.55,14.55,0,1,0,30.55,16,14.57,14.57,0,0,0,16,1.45Z"/>',
  alert_2:
    '<path d="M31.73,29.62H0.27L16,2.38ZM2,28.64H30L16,4.34Z"/><rect x="15.51" y="9.65" width="0.98" height="14.91"/><circle cx="16" cy="26.61" r="0.9"/>',
  back:
    '<path d="M20.39,25.52a0.6,0.6,0,0,1-.44-0.19L11,16l8.94-9.33a0.6,0.6,0,1,1,.87.84L12.68,16l8.14,8.5A0.6,0.6,0,0,1,20.39,25.52Z"/>',
  check:
    '<path d="M11.66,26.25l-9-10.5a0.5,0.5,0,1,1,.76-0.65l8.3,9.64,17-18.58a0.5,0.5,0,1,1,.74.67Z"/>',
  expand_fields:
    '<path d="M16,19.82l-6.89-6.6a0.61,0.61,0,0,1,.84-0.87L16,18.15l6.06-5.8a0.61,0.61,0,0,1,.84.87Z"/>',
  expand_scroll: '<polyline points="30.81 9.59 16 22.41 1.19 9.59"/>',
  close:
    '<path d="M7.53,24.91a0.44,0.44,0,0,1-.31-0.75L24.16,7.21a0.44,0.44,0,1,1,.62.62L7.84,24.79A0.44,0.44,0,0,1,7.53,24.91Z"/><path d="M24.47,24.91a0.44,0.44,0,0,1-.31-0.13L7.21,7.84a0.44,0.44,0,1,1,.62-0.62L24.79,24.16A0.44,0.44,0,0,1,24.47,24.91Z"/>',
  clock:
    '<path d="M16.09,10.92a0.63,0.63,0,0,0-.63.63v8.54l-4.16-3a0.63,0.63,0,1,0-.74,1l6.15,4.51v-11A0.63,0.63,0,0,0,16.09,10.92Z"/><path d="M27.08,8.72l1.09,1.09,1.55-1.55L26,4.49,24.41,6,25.5,7.14,24.43,8.21a13.57,13.57,0,0,0-6.75-2.95v-2h2.09v-3H12.56v3h2.09V5.18A13.4,13.4,0,0,0,2.28,18.42a13.42,13.42,0,0,0,13.52,13.3,13.42,13.42,0,0,0,13.52-13.3A13.11,13.11,0,0,0,26,9.76Zm1,9.71a12.17,12.17,0,0,1-12.26,12,12.17,12.17,0,0,1-12.26-12,12.17,12.17,0,0,1,12.26-12A12.17,12.17,0,0,1,28.06,18.42Z"/>',
  info:
    '<path d="M16,30.2A14.2,14.2,0,1,1,30.2,16,14.21,14.21,0,0,1,16,30.2ZM16,3.06A12.94,12.94,0,1,0,28.94,16,13,13,0,0,0,16,3.06Z"/><path d="M16,24.11a0.63,0.63,0,0,1-.63-0.63V13.15a0.63,0.63,0,0,1,1.25,0V23.49A0.63,0.63,0,0,1,16,24.11Z"/><circle cx="16" cy="9.61" r="1.1"/>',
  doctor:
    '<path d="M27.21,8.78a2.48,2.48,0,1,0-2.89,2.44V24.83a5.64,5.64,0,0,1-11.28,0V19.17a7.53,7.53,0,0,0,6.78-7.48V0.7H16.75V1.53H19V11.69a6.69,6.69,0,1,1-13.39,0V1.53H7.86V0.7H4.79v11a7.52,7.52,0,0,0,7.43,7.51v5.63a6.46,6.46,0,0,0,12.93,0V11.22A2.48,2.48,0,0,0,27.21,8.78Z"/>',
  menu:
    '<path d="M25.68,10.76H6.32a0.6,0.6,0,0,1,0-1.21H25.68A0.6,0.6,0,0,1,25.68,10.76Z"/><path d="M25.68,16.6H6.32a0.6,0.6,0,0,1,0-1.21H25.68A0.6,0.6,0,0,1,25.68,16.6Z"/><path d="M25.68,22.45H6.32a0.6,0.6,0,0,1,0-1.21H25.68A0.6,0.6,0,1,1,25.68,22.45Z"/>',
  less:
    '<path d="M24.51,16.34a0.48,0.48,0,0,1-.34.14H7.83a0.48,0.48,0,1,1,0-1H24.17A0.48,0.48,0,0,1,24.51,16.34Z"/>',
  next:
    '<path d="M11.61,6.48a0.6,0.6,0,0,1,.44.19L21,16l-8.94,9.33a0.6,0.6,0,1,1-.87-0.84L19.32,16,11.18,7.5A0.6,0.6,0,0,1,11.61,6.48Z"/>',
  play_2:
    '<polygon points="8.43 16 8.43 1.09 16 8.54 23.57 16 16 23.46 8.43 30.91 8.43 16"/>',
  play:
    '<polygon points="13.2 16 13.2 6.54 18 11.27 22.8 16 18 20.73 13.2 25.46 13.2 16"/><path d="M16,31.55A15.55,15.55,0,1,1,31.55,16,15.57,15.57,0,0,1,16,31.55Zm0-30.1A14.55,14.55,0,1,0,30.55,16,14.57,14.57,0,0,0,16,1.45Z"/><',
  more:
    '<path d="M16.34,29.51a0.48,0.48,0,0,1-.83-0.34V2.83a0.48,0.48,0,1,1,1,0V29.17A0.48,0.48,0,0,1,16.34,29.51Z"/><path d="M29.51,16.34a0.48,0.48,0,0,1-.34.14H2.83a0.48,0.48,0,1,1,0-1H29.17A0.48,0.48,0,0,1,29.51,16.34Z"/>',
  profile:
    '<path d="M19.59,16.4a6.39,6.39,0,0,0,2.91-5.34,6.51,6.51,0,0,0-13,0,6.39,6.39,0,0,0,2.91,5.34,7.61,7.61,0,0,0-6,7.38v3a0.63,0.63,0,0,0,1.25,0v-3a6.39,6.39,0,0,1,6.45-6.31h3.84a6.39,6.39,0,0,1,6.45,6.31v3a0.63,0.63,0,0,0,1.25,0v-3A7.61,7.61,0,0,0,19.59,16.4Zm-8.84-5.34A5.25,5.25,0,1,1,16,16.22,5.21,5.21,0,0,1,10.75,11.06Z"/>',
  program_play:
    '<path d="M28.16,27.53H3.84A3.74,3.74,0,0,1,.1,23.79V7.26A3.74,3.74,0,0,1,3.84,3.52H28.16A3.74,3.74,0,0,1,31.9,7.26V23.79A3.74,3.74,0,0,1,28.16,27.53ZM3.84,4.73A2.54,2.54,0,0,0,1.31,7.26V23.79a2.54,2.54,0,0,0,2.53,2.53H28.16a2.54,2.54,0,0,0,2.53-2.53V7.26a2.54,2.54,0,0,0-2.53-2.53H3.84Z"/><path d="M14.07,22.76V8.29l6.4,7.23Zm1.21-11.28v8.1l3.59-4.05Z"/>',
  refresh:
    '<path d="M27.13,9A14.19,14.19,0,0,0,9,3.56L7.64,1.39,6.16,4.1,4.69,6.8l3.08-.07,3.08-.07-1.23-2A12.94,12.94,0,0,1,21.74,27.46,12.94,12.94,0,0,1,4,23.14a0.63,0.63,0,0,0-1.07.65,14.1,14.1,0,0,0,8.77,6.41,14.29,14.29,0,0,0,3.38.41A14.2,14.2,0,0,0,27.13,9Z"/>',
  pause: '<path d="M4 4h10v24h-10zM18 4h10v24h-10z"></path>',
  arrowLeft:
    '<line stroke-width="1" x1="16" y1="0" x2="0" y2="16" /><line stroke-width="1" x1="0" y1="16" x2="16" y2="32" />',
  arrowRight:
    '<line stroke-width="1" x1="0" y1="0" x2="16" y2="16" /><line stroke-width="1" x1="16" y1="16" x2="0" y2="32" />',
  arrowTop:
    '<line stroke-width="1" x1="0" y1="16" x2="16" y2="0" /><line stroke-width="1" x1="16" y1="0" x2="32" y2="16" />',
  arrowBottom:
    '<line stroke-width="1" x1="0" y1="0" x2="16" y2="16" /><line stroke-width="1" x1="16" y1="16" x2="32" y2="0" />',
  search:
    '<path d="M32,30.1l-9.4-9.5c1.7-2.2,2.8-4.9,2.8-7.9c0-7-5.7-12.7-12.7-12.7C5.7,0,0,5.7,0,12.7s5.7,12.7,12.7,12.7 c3,0,5.8-1.1,8-2.8l9.4,9.5L32,30.1z M4.7,20.7c-2.1-2.1-3.3-5-3.3-8s1.2-5.9,3.3-8s5-3.3,8-3.3s5.9,1.2,8,3.3s3.3,5,3.3,8 s-1.2,5.9-3.3,8c-2.1,2.1-5,3.3-8,3.3C9.6,24,6.8,22.9,4.7,20.7z"/>'
};

const Icon = ({
  name,
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  className = '',
  fill = '#000',
  ...others
}) => {
  const html = paths[name];

  return (
    <svg
      className={`Icon ${className} ${!html ? 'is-empty' : ''}`}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      strokeWidth={0}
      stroke={fill}
      {...others}
    >
      <g dangerouslySetInnerHTML={{ __html: html }} />
    </svg>
  );
};

export default Icon;
